<template>
  <div>
    <div v-show="!loading">
      <div v-html="content"></div>
    </div>
    <v-container v-show="loading" style="height: 400px">
      <v-row class="fill-height" align-content="center" justify="center">
        <v-col class="subtitle-1 text-center" cols="12">
          Loading Content
        </v-col>
        <v-col cols="6">
          <v-progress-linear
            color="primary accent-4"
            indeterminate
            rounded
            height="6"
          ></v-progress-linear>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
      content: '',
    }
  },
  mounted() {
    this.getData()
  },
  methods: {
    async getData() {
      try {
        this.loading = true
        const response = await this.$api.get(`pages/terms_of_service`)
        this.content = response.data
      } finally {
        this.loading = false
      }
    },
  },
}
</script>
