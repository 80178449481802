<template>
  <empty-layout>
    <router-view />
  </empty-layout>
</template>

<script>
import EmptyLayout from '@/layouts/EmptyLayout'

export default {
  components: { EmptyLayout },
}
</script>
